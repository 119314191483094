:root {
  --primary-color-50: #eef2ff;
  --primary-color-100: #a5b4fc;
  --primary-color-main: #6366f1;
  --primary-color-300: #4338ca;
  --primary-color-400: #4338ca;

  --secondary-color-50: #f9e9e0;
  --secondary-color-100: #f9a77d;
  --secondary-color-main: #f97f40;
  --secondary-color-300: #f95500;
  --secondary-color-400: #c74400;

  --success-color-50: #f0fdf4;
  --success-color-100: #dcfce2;
  --success-color-200: #86efac;
  --success-color-300: #22c55e;
  --success-color-400: #15803d;

  --warning-color-50: #fffbeb;
  --warning-color-100: #fef3c7;
  --warning-color-200: #fde68a;
  --warning-color-300: #fcd34d;
  --warning-color-400: #fbbf24;

  --error-color-50: #fef2f2;
  --error-color-100: #fca5a5;
  --error-color-200: #ef4444;
  --error-color-main: #ff4d4f;
  --error-color-300: #dc2626;
  --error-color-400: #7f1d1d;

  --gray-color-50: #fafafa;
  --gray-color-100: #f5f5f5;
  --gray-color-200: #e5e5e5;
  --gray-color-300: #d4d4d4;
  --gray-color-400: #a3a3a3;
  --gray-color-500: #737373;
  --gray-color-600: #525252;
  --gray-color-700: #404040;
  --gray-color-800: #262626;
  --gray-color-900: #171717;

  --natural-color-50: #f8fafc;
  --natural-color-100: #f1f5f9;
  --natural-color-200: #e2e8f0;
  --natural-color-opacity-200: #e2e8f0a5;
  --natural-color-300: #cbd5e1;
  --natural-color-400: #94a3b8;
  --natural-color-500: #64748b;
  --natural-color-600: #475569;
  --natural-color--opacity-700: #334155a5;
  --natural-color-700: #334155;
  --natural-color-800: #1e293b;
  --natural-color-900: #0f172a;

  --blue-color-main: #3073fd;

  --color-main: #334155;
  --color-light: #f8fafc;
  --color-disable: #94a3b8;
  --color-placeholder: #64748b;
  --color-paragraph: #475569;

  --black-color: #000;
  --white-color: #fff;
  --white-natural-color: #f8fafc;
  --blue-light-color: #eef2ff;

  --skeleton-color: var(--gray-color-200);
  --input-disable-bg: var(--gray-color-100);
  --input-color: var(--primary-color-main);
  --border-color: #d9d9d9;
  --input-label-bg: var(--white-natural-color);
  --body-bg: var(--gray-color-200);
  --block-item-bg: var(--white-color);
  --shareLink-bg: var(--primary-color-50);
  --radio-bg: var(--primary-color-50);
  --svg-color: #475569;
  --svg-color-2: var(--primary-color-main);

  --block_title_color: #fff;
  --block_name_color: #fff;
  --block_background_color:  transparent;
  --block_border_color: #e2e8f0;
  --divider_color: #fff;
  --profile_title_color: #fff;
  --profile_description_color: #fff;
  --default_color: #fff;
  --primary_button_color: #6366f1;
  --ghost_button_color: #fff;
}

[color-scheme='dark'] {
  --body-bg: #0f1a2d;
  --white-color: #344054;
  --color-main: #f2f4f7;
  --white-natural-color: #1d2939;
  --color-paragraph: #b6c1cb;
  --color-placeholder: #8996a6;
  --blue-light-color: #222d3b;

  --natural-color-50: #0f172a;
  --natural-color-100: #1e293b;
  --natural-color-200: #334155;
  --natural-color-opacity-200: #334155a5;
  --natural-color-300: #475569;
  --natural-color-400: #64748b;
  --natural-color-500: #94a3b8;
  --natural-color-600: #cbd5e1;
  --natural-color--opacity-700: #e2e8f0a5;
  --natural-color-700: #e2e8f0;
  --natural-color-800: #f1f5f9;
  --natural-color-900: #f8fafc;

  --gray-color-50: #232d3e;
  --gray-color-100: #262626;
  --gray-color-200: #404040;
  --gray-color-300: #525252;
  --gray-color-400: #737373;
  --gray-color-500: #a3a3a3;
  --gray-color-600: #d4d4d4;
  --gray-color-700: #e5e5e5;
  --gray-color-800: #f5f5f5;
  --gray-color-900: #fafafa;

  --skeleton-color: #4f5f7a;
  --input-text: var(--primary-color-100);
  --input-label-bg: var(--white-natural-color);
  --block-item-bg: var(--white-color);
  --shareLink-bg: var(--white-color);
  --radio-bg: var(--color-main);
  --input-disable-bg: var(--white-color);
  --border-color: #818181;
  --svg-color: #fff;
  --svg-color-2: #fff;
}
