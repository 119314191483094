.dataPicker-input {
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: 'tnum';
    position: relative;
    height: 50px;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 12px 16px;
    color: var(--color-main);
    font-size: 14px;
    line-height: 1.5715;
    background-image: none;
    border: 1px solid var(--border-color);
    background-color: var(--input-label-bg);
    transition: all 0.3s;
    border-radius: 6px !important;
}

.dataPicker-input:focus {
    border-color: var(--primary-color-main) !important;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 var(--primary-color-main);
}

.dataPicker-input:hover {
    border-color: var(--primary-color-main) !important;
}

.date-picker-disable:hover{
    cursor: not-allowed;
    border-color: var(--border-color) !important;
}