.ant-select + .float-label {
	top: 15px;
	width: auto;
}

.float-group .ant-input:focus ~ .float-label,
.float-group input[inputmode='numeric']:focus ~ .float-label {
	top: -10px;
	width: auto;
	height: 15px;
	color: var(--input-color);
	background-color: var(--input-label-bg);
	padding: 0.1em 0.6em;
	font-size: 0.8rem;
	border-radius: 0.25em;
}

.ant-input-number-focused ~ .float-label {
	top: -10px;
	width: auto;
	height: 15px;
	color: var(--input-color);
	background-color: var(--input-label-bg);
	padding: 0.1em 0.6em;
	font-size: 0.8rem;
	border-radius: 0.25em;
}

.ant-select-focused ~ .float-label {
	top: -10px;
	width: auto;
	height: 15px;
	color: var(--input-color);
	background-color: var(--input-label-bg);
	padding: 0.1em 0.6em;
	font-size: 0.8rem;
	border-radius: 0.25em;
}

.ant-input-affix-wrapper-focused ~ .float-label {
	top: -10px;
	width: auto;
	height: 15px;
	color: var(--input-color);
	background-color: var(--input-label-bg);
	padding: 0.1em 0.6em;
	font-size: 0.8rem;
	border-radius: 0.25em;
}

.ant-input.ant-input-disabled ~ .float-label,
.ant-input-affix-wrapper-disabled ~ .float-label,
.ant-input-number-disabled ~ .float-label {
	color: var(--gray-color-500);
}

.ant-input.ant-input-disabled ~ .float-label.active-float-label,
.ant-input-affix-wrapper-disabled ~ .float-label.active-float-label,
.ant-input-number-disabled ~ .float-label.active-float-label,
.ant-input-affix-wrapper-disabled,
.ant-input.ant-input-disabled ~ .float-label,
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.date-picker-disable,
.ant-select-disabled ~ .float-label {
	color: var(--gray-color-500) !important;
	background-color: var(--input-disable-bg) !important;
}

.float-group {
	position: relative;
	width: 100%;
}

.float-label {
	position: absolute;
	z-index: 10;
	top: 14px;
	text-align: right;
	width: 95%;
	font-size: 1rem;
	background-color: var(--input-label-bg);
	color: var(--gray-color-500);
	pointer-events: none;
	padding: 0 4px;
	transition: all 200ms ease-in-out;
}

.active-float-label {
	top: -10px !important;
	height: 15px;
	width: auto;
	color: var(--input-color);
	background-color: var(--input-label-bg);
	padding: 0.1em 0.6em;
	font-size: 0.8rem;
	border-radius: 0.25em;
}

.active-float-label-select {
	top: -10px !important;
	width: auto;
	height: 15px;
	color: var(--input-color);
	background-color: var(--input-label-bg);
	padding: 0.1em 0.6em;
	font-size: 0.8rem;
	border-radius: 0.25em;
}


/* number */
.float-group input[inputmode='numeric']:focus {
	border-color: var(--primary-color-300) !important;
}

.float-group input.input-error,
.float-group input.input-error:focus {
	border-color: var(--error-color-main) !important;
}
