.blockList.ant-collapse-item {
  border-radius: 8px;
}

.blockList.template-preview {
  border-bottom: 1px solid var(--block_border_color) !important;
}

.blockList .ant-collapse-header {
	flex-direction: row-reverse !important;
  align-items: center !important;
}

.blockList-bg .ant-collapse-content {
  border-top: 0 !important;
  background-color: var(--white-natural-color) !important;
}

.linkPreview .ant-collapse-content {
  border-top: 0 !important;
  background-color: var(--bg-gray-color-100) !important;
}

.blockList .ant-collapse-header .ant-collapse-expand-icon {
  padding-inline-end: 0 !important;
}