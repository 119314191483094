.btn-size--xs {
  font-size: 1rem;
  min-width: 117px;
  height: 40px;
  padding: 8px 12px;
}

.btn-size--sm {
  font-size: 1rem;
  min-width: 122px;
  height: 40px;
  padding: 8px 12px;
}

.btn-size--base {
  font-size: 1rem;
  min-width: 138px;
  height: 44px;
  padding: 10px 20px;
}

.btn-size--lg {
  font-size: 1rem;
  min-width: 143px;
  height: 48px;
  padding: 12px 20px;
}

.btn-size--xl {
  font-size: 1rem;
  min-width: 151px;
  height: 52px;
  padding: 14px 24px;
}
