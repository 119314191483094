blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
	margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-size: inherit;
	font-weight: inherit;
}

ol,
ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

img,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: middle;
}

img,
video {
  max-width: 100%;
  height: auto;
}

*,
::before,
::after {
  border-width: 0;
  border-style: solid;
}

.google-map * {
  border-style: none;
}