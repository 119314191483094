//yekan bakh
@font-face {
  font-family: YekanBakh;
  src: url('/assets/fonts/yekanBakh/YekanBakhFaNumMedium.eot');
  src: url('/assets/fonts/yekanBakh/YekanBakhFaNumMedium.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/yekanBakh/YekanBakhFaNumMedium.woff') format('woff'),
    url('/assets/fonts/yekanBakh/YekanBakhFaNumMedium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: YekanBakh;
  src: url('/assets/fonts/yekanBakh/YekanBakhFaNumBold.eot');
  src: url('/assets/fonts/yekanBakh/YekanBakhFaNumBold.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/yekanBakh/YekanBakhFaNumBold.woff') format('woff'),
    url('/assets/fonts/yekanBakh/YekanBakhFaNumBold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: YekanBakh;
  src: url('/assets/fonts/yekanBakh/YekanBakhFaNumHeavy.eot');
  src: url('/assets/fonts/yekanBakh/YekanBakhFaNumHeavy.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/yekanBakh/YekanBakhFaNumHeavy.woff') format('woff'),
    url('/assets/fonts/yekanBakh/YekanBakhFaNumHeavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}