/* select */
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
	height: 50px;
	background-color: var(--input-label-bg);
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
	font-size: 16px;
	height: 100%;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
	top: 15px;
	font-size: 16px;
}

/* radio */
.radio-card .ant-radio + span {
	flex: 1;
}

:where(.css-dev-only-do-not-override-1xfrs3p).ant-radio-wrapper .ant-radio-inner {
	background-color: var(--radio-bg);
}

/* input */
.ant-input {
	background-color: var(--input-label-bg);
}

:where(.css-dev-only-do-not-override-aorv09).ant-input-affix-wrapper > input.ant-input {
	background-color: var(--bg-natural-color-50);
}

.ant-input-number-input {
	padding: 0 !important;
}

.ant-input-affix-wrapper-rtl {
	gap: 5px;
}

.ant-input-suffix {
	gap: 3px;
}

/* drawer */
.ant-drawer-content.drawer-rounded-top {
	border-top-right-radius: 30px !important;
	border-top-left-radius: 30px !important;
}

.ant-drawer-content-wrapper {
	box-shadow: none !important;
}

.ant-drawer-wrapper-body {
	position: relative;
}

.ant-drawer-mask {
	max-width: 448px;
	margin: 0 auto;
}

.ant-drawer {
	z-index: 9999;
}

/* button */
.ant-btn-primary.ant-btn-background-ghost:not(:disabled):hover,
.ant-btn-primary.ant-btn-background-ghost {
	color: var(--primary-color-main);
	border-color: var(--primary-color-main);
}

.ant-btn-link:not(:disabled):hover,
.ant-btn-link {
	color: var(--primary-color-main);
}

/* progress step */
.ant-steps-item-container {
	align-items: center;
}

/* input number */

.ant-input-number {
	width: 100%;
}

.ant-input-number-input {
	padding: 0;
}

.suffix-icon {
	position: absolute;
	top: 10px;
	left: 10px;
}

.suffix-icon-ltr {
	position: absolute;
	top: 10px;
	right: 10px;
}

.ant-input-number-input,
.ant-input-number-input-wrap {
	height: 100%;
}

/* float button*/
.ant-float-btn .ant-float-btn-body .ant-float-btn-content,
.ant-float-btn-primary .ant-float-btn-body .ant-float-btn-content .ant-float-btn-icon {
	width: 100%;
}

.ant-float-btn-group .ant-float-btn {
	width: 100%;
	height: 100%;
}

.ant-float-btn-group.ant-float-btn-group-rtl {
	width: auto;
}


/* collapse */

.block-collapse .ant-collapse-header {
	padding: 8px !important;
}